import { render, staticRenderFns } from "./GlobalQueryBuilderEditor.vue?vue&type=template&id=4cb0e0f9&scoped=true"
import script from "./GlobalQueryBuilderEditor.vue?vue&type=script&lang=js"
export * from "./GlobalQueryBuilderEditor.vue?vue&type=script&lang=js"
import style0 from "./GlobalQueryBuilderEditor.vue?vue&type=style&index=0&id=4cb0e0f9&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cb0e0f9",
  null
  
)

export default component.exports